<template>
	<div class="container">
		<el-breadcrumb separator-class="el-icon-arrow-right" style="	height: 40px;">
			<el-breadcrumb-item> {{ fun.getLanguageText($store.state.language, '设备管理') }}</el-breadcrumb-item>
			<el-breadcrumb-item> {{ fun.getLanguageText($store.state.language, '设备类型管理') }}</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="top">
			<el-input v-model="searchData.value"
				:placeholder="fun.getLanguageText($store.state.language, '请输入关键词进行搜索')"></el-input>
			<el-button type="primary" icon="el-icon-search" @click="search">
				{{ fun.getLanguageText($store.state.language, '搜索') }}
			</el-button>
			<el-select v-model="searchData.type1" :placeholder="fun.getLanguageText($store.state.language, '请选择设备类型')"
				@change="changeSearchData($event,1)">
				<el-option label="全部设备类型" :value="null"></el-option>
				<el-option :label="item.name" :value="item.id" v-for="item in DeviceTypeList1" :key="item.id">
				</el-option>
			</el-select>
			<el-select v-model="searchData.type2" :placeholder="fun.getLanguageText($store.state.language, '请选择设备名称')"
				@change="changeSearchData($event,2)">
				<el-option label="全部设备名称" :value="null"></el-option>
				<el-option :label="item.name" :value="item.id" v-for="item in DeviceTypeList2" :key="item.id">
				</el-option>
			</el-select>
			<el-select v-model="searchData.type3" :placeholder="fun.getLanguageText($store.state.language, '请选择设备型号')"
				@change="changeSearchData($event,3)">
				<el-option label="全部设备型号" :value="null"></el-option>
				<el-option :label="item.name" :value="item.id" v-for="item in DeviceTypeList3" :key="item.id">
				</el-option>
			</el-select>
			<el-button type="primary" icon="el-icon-plus" class="add-username" @click="openDia(1)">
				{{ fun.getLanguageText($store.state.language, '设备类型') }}
			</el-button>
			<el-button type="primary" icon="el-icon-plus" class="add-username" @click="openDia(2)">
				{{ fun.getLanguageText($store.state.language, '设备名称') }}
			</el-button>
			<el-button type="primary" icon="el-icon-plus" class="add-username" @click="openDia(3)">
				{{ fun.getLanguageText($store.state.language, '设备型号') }}
			</el-button>
		</div>
		<!-- 滚动窗口 -->
		<div style="width: 100%; height: 48px; display: flex; color: #909399;">
			<div style="flex: 1; height: 100%; padding-left: 30px; line-height: 48px;">
				{{ fun.getLanguageText($store.state.language, '设备类型') }}
			</div>
			<div style="flex: 1; height: 100%; padding-left: 30px; line-height: 48px;">
				{{ fun.getLanguageText($store.state.language, '设备名称') }}
			</div>
			<div style="flex: 1; height: 100%; padding-left: 30px; line-height: 48px;">
				{{ fun.getLanguageText($store.state.language, '设备型号') }}
			</div>
		</div>
		<div class="scroll" :style="'height:' + ($store.state.clientHeight-40) + 'px;'">
			<div class="scroll-item" v-for="item in list">
				<div style="min-width: 200px; flex: 1;" class="scroll-text">
					<div>{{ item.name }}</div>
					<div>
						<el-button type="text" size="small" @click="open(item)">
							{{ fun.getLanguageText($store.state.language, '修改') }}
						</el-button>
						<el-button type="text" size="small" @click="del(item.id)">
							{{ fun.getLanguageText($store.state.language, '删除') }}

						</el-button>
					</div>
				</div>
				<div class="scroll-item-1" style="flex: 2;">
					<div v-for="item_1 in item.second" class="scroll-item-2">
						<div style="min-width: 200px;flex: 1;" class="scroll-text">
							<div>{{ item_1.name }}</div>
							<div>
								<el-button type="text" size="small" @click="open(item_1)">
									{{ fun.getLanguageText($store.state.language, '修改') }}
								</el-button>
								<el-button type="text" size="small" @click="del(item_1.id)">
									{{ fun.getLanguageText($store.state.language, '删除') }}
								</el-button>
							</div>
						</div>
						<div class="scroll-item-3" style="flex: 1;">
							<div v-for="item_2 in item_1.third" class="scroll-item-4">
								<div style="min-width: 200px;flex: 1;" class="scroll-text">
									<div>{{ item_2.name }}</div>
									<div>
										<el-button type="text" size="small" @click="open(item_2)">
											{{ fun.getLanguageText($store.state.language, '修改') }}
										</el-button>
										<el-button type="text" size="small" @click="del(item_2.id)">
											{{ fun.getLanguageText($store.state.language, '删除') }}
										</el-button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-pagination class="footer" background layout="sizes, prev, pager, next, jumper, total"
			:page-size="pageData.pageSize" :current-page="pageData.currentPage" :total="pageData.total"
			:page-sizes="pageData.pageSizes" @size-change="sizeChange" @current-change="currentChange">
		</el-pagination>
		<el-dialog :title="fun.getLanguageText($store.state.language, '添加设备型号数据')" :visible.sync="dialogFormVisible">
			<el-form :model="formData">
				<el-form-item :label="fun.getLanguageText($store.state.language, '设备类型')" :label-width="formLabelWidth">
					<el-select v-model="formData.type1.id" value-key="name" v-if="diaType > 1"
						@change="changeSearchDataDia($event,1)">
						<el-option :label="item.name" :value="item.id" v-for="item in diaDeviceTypeList1"
							:key="item.id"></el-option>
					</el-select>
					<el-input v-model="formData.type1.name" autocomplete="off" v-if="diaType == 1"></el-input>
				</el-form-item>
				<el-form-item :label="fun.getLanguageText($store.state.language, '设备名称')" :label-width="formLabelWidth"
					v-if="diaType>1">
					<el-select v-model="formData.type2.id" v-if="diaType > 2">
						<el-option :label="item.name" :value="item.id" v-for="item in diaDeviceTypeList2"
							:key="item.id"></el-option>
					</el-select>
					<el-input v-model="formData.type2.name" autocomplete="off" v-if="diaType == 2"></el-input>
				</el-form-item>
				<el-form-item :label="fun.getLanguageText($store.state.language, '设备型号')" :label-width="formLabelWidth"
					v-if="diaType>2">
					<el-input v-model="formData.type3.name" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">{{fun.getLanguageText($store.state.language, '取消')}}
				</el-button>
				<el-button type="primary" @click="addDeviceType">{{fun.getLanguageText($store.state.language, '确定')}}
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				list: [],
				searchData: {
					value: '', // 查询内容
					type1: '', // 类型ID
					type2: '', // 名称id
					type3: '', // 型号id
				},
				router: false,
				input: '',
				user_type: '',
				dialogFormVisible: false,
				formLabelWidth: '120px',
				DeviceTypeList1: [],
				DeviceType1: 0,
				DeviceTypeList2: [],
				DeviceType2: 0,
				DeviceTypeList3: [],
				DeviceType3: 0,
				formData: {
					type1: { //类型
						name: '',
						id: ''
					},
					type2: { // 名称
						name: '',
						id: ''
					},
					type3: { // 型号
						name: '',
						id: ''
					}
				},
				diaType: 0,
				diaDeviceTypeList1: [],
				diaDeviceTypeList2: [],
				diaDeviceTypeList3: [],
				pageData: {
					total: 0, // 总条数
					currentPage: 1, // 当前页数
					totalPage: 0, // 总页数
					pageSize: 20, //
					pageSizes: [10, 20, 30, 50]
				},
			}
		},
		created() {
			this.getDeviceShow()
			this.getDeviceTypeList(1)

		},
		watch: {
			dialogFormVisible(e) {
				console.log(e, "弹窗改变")
				if (e) {
					return
				}
				setTimeout(() => {
					this.diaDeviceTypeList1 = []
					this.diaDeviceTypeList2 = []
					this.diaDeviceTypeList3 = []
					this.formData = {
						type1: { //类型
							name: '',
							id: ''
						},
						type2: { // 名称
							name: '',
							id: ''
						},
						type3: { // 型号
							name: '',
							id: ''
						}
					}
				}, 200)

			}
		},
		methods: {
			// 搜索
			search() {
				this.getDeviceShow()
			},
			// 更新文本
			open(e) {
				this.$prompt('请输入修改后的数据', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then((res) => {
					if (res.value != null) {
						return Promise.resolve(this.$http.post('device-type/change', {
							id: e.id,
							name: res.value
						}))
					} else {
						this.$message({
							type: 'info',
							message: this.fun.getLanguageText(this.$store.state.language, '修改数据不能为空')
						});
						return
					}
				}).then(res => {
					console.log(res)
					if (res.data.code == 2000) {
						this.$message({
							type: 'success',
							message: this.fun.getLanguageText(this.$store.state.language, '修改成功')
						});
						this.getDeviceShow()
					} else {
						this.$message({
							type: 'info',
							message: this.fun.getLanguageText(this.$store.state.language, res.data.msg)
						});
					}

				}).catch(() => {
					this.$message({
						type: 'info',
						message: this.fun.getLanguageText(this.$store.state.language, '修改失败')
					});
				});
			},
			// 删除
			del(id) {
				this.$confirm(this.fun.getLanguageText(this.$store.state.language, '此操作将永久删除该节点, 是否继续?'),
						this.fun.getLanguageText(this.$store.state.language, '提示'), {
						confirmButtonText: this.fun.getLanguageText(this.$store.state.language, '确定'),
						cancelButtonText: this.fun.getLanguageText(this.$store.state.language, '取消'),
					type: 'warning'
				}).then(() => {
					return Promise.resolve(this.$http.post('device-type/delete', {
						id
					}))
				}).then(res => {
					if (res.data.code == 2000) {
						this.$message({
							type: 'success',
							message: this.fun.getLanguageText(this.$store.state.language, '删除成功!')
						});
						this.getDeviceShow()
						return
					} else {
						this.$message({
							type: 'info',
							message: this.fun.getLanguageText(this.$store.state.language, res.data.msg)
						});
					}
				}).catch(() => {
					this.$message({
						type: 'info',
						message: this.fun.getLanguageText(this.$store.state.language, '已取消删除')
					});
				});

			},
			test(e) {
				console.log(e)
			},
			// 改变页码
			currentChange(e) {
				this.pageData.currentPage = e
				this.getDeviceShow()
			},
			// 选择页码
			sizeChange(e) {
				this.pageData.pageSize = e
				this.getDeviceShow()
			},
			// 下拉选择框被改变
			changeSearchDataDia(e, id) {
				console.log(e, id)
				if (id === 1) {
					this.getDeviceTypeList(2, e)
				}
				if (id === 2) {
					this.getDeviceTypeList(3, e)
				}
				if (id === 3) {
					this.DeviceType3 = e
				}
				console.log(this.searchData)
			},
			// 下拉选择框被改变
			changeSearchData(e, id) {
				console.log(e, id)
				if (id === 1) {
					this.DeviceType1 = e
					this.searchData.type2 = ''
					this.searchData.type3 = ''
					this.DeviceTypeList2 = []
					this.DeviceTypeList3 = []
					this.getDeviceTypeList(2, e)
				}
				if (id === 2) {
					this.DeviceType2 = e
					this.searchData.type3 = ''
					this.DeviceTypeList3 = []
					this.getDeviceTypeList(3, e)
				}
				if (id === 3) {
					this.DeviceType3 = e
				}
				this.getDeviceShow()
				console.log(this.searchData)
			},
			// 读取类型下拉数据
			getDeviceTypeList(type, pid) {
				this.$http.post('device-type/list', {
					type,
					pid
				}).then(res => {
					if (res.data.code === 2000) {
						if (type == 1) {
							console.log('打开')
							this.DeviceTypeList1 = res.data.data
							this.diaDeviceTypeList1 = res.data.data

						}
						if (type == 2) {
							this.DeviceTypeList2 = res.data.data
							this.diaDeviceTypeList2 = res.data.data
						}
						if (type == 3) {
							this.DeviceTypeList3 = res.data.data
							this.diaDeviceTypeList3 = res.data.data
						}
					}
					console.log(res)
				}).catch(err => {
					console.log(err)
				})
			},
			// 打开修改界面
			openDia(e) {
				this.diaType = e
				this.getDeviceTypeList(1)
				this.dialogFormVisible = true
			},
			// 添加设备数据
			addDeviceType() {
				let e = this.diaType
				let data = {}
				if (e == 1) {
					data = {
						type: e,
						name: this.formData.type1.name,
						type_name: '设备类型'
					}
				}
				if (e == 2) {
					data = {
						type: e,
						name: this.formData.type2.name,
						pid: this.formData.type1.id,
						type_name: '设备名称'
					}
				}
				if (e == 3) {
					data = {
						type: e,
						name: this.formData.type3.name,
						pid: this.formData.type2.id,
						type_name: '设备型号'
					}
				}
				console.log(data, this.formData.type2.name, e)
				this.$http.post('device-type/create', data).then(res => {
					if (res.data.msg === "success") {
						// 成功
						console.log('添加成功')
						this.searchData.type1 = ''
						this.searchData.type2 = ''
						this.searchData.type3 = ''
						this.getDeviceTypeList(1)
						this.dialogFormVisible = false
						this.getDeviceShow()
						this.$message({
							type: 'success',
							message: `${this.fun.getLanguageText(this.$store.state.language, '添加成功')}[${data.type_name}]----${data.name}`
						});
						return
					}
					console.log(res)
				}).catch(err => {
					console.log(err)
				})
			},
			// 读取数据
			getDeviceShow() {
				this.$http.post('device-type/show', {
					id_1: this.searchData.type1,
					id_2: this.searchData.type2,
					id_3: this.searchData.type3,
					search: this.searchData.value,
					currentPage: this.pageData.currentPage,
					pageSize: this.pageData.pageSize
				}).then(res => {
					if (res.data.code !== 2000) {
						return
					}
					if (res.data.data.length > 2) {
						res.data.data.length = 2
					}
					console.log(res.data)
					this.list = res.data.data.info
					this.pageData.total = Number(res.data.data.page.total)
				})
			},
			// 计算高度
			getListHeight() {
				let Html = document.getElementsByClassName('box-card')
				if (Html.length > 0) {
					this.$store.state.clientHeight = Html[0].clientHeight - 131 - 60

				}
			}
		},
		mounted() {
			this.getListHeight()
			window.onresize = () => {
				return (() => {
					this.getListHeight()
				})();
			}
		},
		destroyed() {
			window.onresize = null;
		}
	};
</script>

<style lang="less" scoped>
	div {
		box-sizing: border-box;
	}

	.el-dialog {
		.el-input {
			width: 240px;
		}

		.el-select {
			width: 240px;

		}
	}

	.top {
		.el-input {
			width: 220px;
			margin-right: 10px;
		}

		.el-select {
			width: 180px;
			margin-left: 20px;
		}
	}


	.container {
		display: flex;
		flex-direction: column;
	}

	.top {
		// height: 32px;
		margin-bottom: 20px;
		position: relative;
		width: 100%;
		display: flex;
	}

	.add-username+.add-username {
		margin-left: 10px;
	}

	.add-username {
		margin-left: 20px;
		position: relative;
		right: 0;
	}

	.footer {
		display: flex;
		padding: 0;
		margin-top: 14px;
		justify-content: flex-end;
	}

	.main {
		// background-color: #0000FF;
		width: 100%;
		height: 622px;
	}

	.mian-header {
		width: 100%;
		height: 50px;
		background-color: #E1F0F9;
		border: 1px solid rgba(0, 0, 0, 0.1);
		box-sizing: border-box;
	}

	.main-item {
		display: flex;
		width: 100%;
		height: 286px;
		box-sizing: border-box;
		// border: 1px solid rgba(255, 255, 255, 100);
		// box-shadow: 1px 1px 0px 0px rgba(187, 187, 187, 100);
	}

	.item-left {
		box-sizing: border-box;
		width: 25%;
		height: 286px;
		// background-color: #F5F5F5;
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-right-width: 0;
		border-top-width: 0;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.item-middle {
		box-sizing: border-box;
		width: 25%;
		height: 286px;
		// background-color: #A5A5A5;
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-right-width: 0;
		border-top-width: 0;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.item-right {
		box-sizing: border-box;
		width: 50%;
		height: 286px;
		// background-color: #B5B5B5;
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-top-width: 0;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.item-left-name {}

	.item-left-btns {
		position: absolute;
		right: 45px;

		.el-button {
			margin-left: 0;
			display: block;
		}
	}

	.item-middle {
		height: 100%;
	}

	.item-middle-item {
		height: 50%;
		width: 100%;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;

	}

	.item-middle-item+.item-middle-item {
		border-top: 1px solid rgba(0, 0, 0, 0.1);
		;
	}

	.item-middle-item-btns {
		position: absolute;
		right: 45px;

		.el-button {
			margin-left: 0;
			display: block;
		}
	}

	.item-right-item {
		height: 25%;
		width: 100%;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.item-right-item-btns {
		position: absolute;
		right: 45px;

		.el-button {
			margin-left: 0;
			display: block;
		}
	}

	.item-right-item+.item-right-item {
		border-top: 1px solid rgba(0, 0, 0, 0.1);

	}

	.scroll {
		overflow-y: scroll;
		overflow-x: hidden;
		height: 100%;
		width: 100%;
		background-color: #FFFFFF;
		display: flex;
		flex-direction: column;
		border-top: 1px solid #EBEEF5;
		border-left: 1px solid #EBEEF5;
		border-bottom: 1px solid #EBEEF5;
	}

	.scroll-item {
		display: flex;
		flex-direction: row;
		border-bottom: 1px solid #EBEEF5;
		align-items: center;

	}

	.scroll .scroll-item:last-child {
		// border-bottom: 0 solid #EBEEF5;
	}

	.scroll-item-1 {
		display: flex;
		flex: 1;
		flex-direction: column;
		border-left: 1px solid #EBEEF5;
	}

	.scroll-item-2 {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.scroll-item-3 {
		display: flex;
		flex-direction: column;
		border-left: 1px solid #EBEEF5;
	}

	.scroll-text {
		height: 57px;
		display: flex;
		align-items: center;
		box-sizing: border-box;
		justify-content: space-around;
	}

	.scroll-text>div {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.scroll-item-2+.scroll-item-2 {
		border-top: 1px solid #EBEEF5;
	}

	.scroll-item-4+.scroll-item-4 {
		border-top: 1px solid #EBEEF5;
	}
</style>
